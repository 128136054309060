import Echo from 'laravel-echo'
import { defineStore } from 'pinia'
import { apiStore } from '~/stores/api'
import { DisplayType, mapStringToDisplayType, View } from '~/types/view-elements'
import { useSockets } from './sockets'

export const VIEWS_CHANNEL = 'Knowledge.Views'

export enum ViewsEvents {
  CREATED = '.knowledge.view.created',
  UPDATED = '.knowledge.view.updated',
  DELETED = '.knowledge.view.deleted',
}

interface State {
  views: View[]
  currentView: View | null
  currentImportedView: View | null
  highlightedEntityType: string
  displayType: DisplayType
  canCreate: boolean
  loaded: boolean
  socket: Echo | null
}

export const viewsStore = defineStore({
  id: 'views',
  state: (): State => ({
    views: [],
    currentView: null,
    currentImportedView: null,
    highlightedEntityType: '',
    displayType: DisplayType.Tree,
    canCreate: false,
    loaded: false,
    socket: null,
  }),
  getters: {
    getViews(): View[] {
      return this.views
    },
    getSocketClient: async (state): Promise<Echo> => {
      if (state.socket) return state.socket

      const sockets = useSockets()
      state.socket = await sockets.getClient()
      return state.socket
    },
  },
  actions: {
    async reload({ fetchAllViews = false } = {}) {
      this.loaded = false
      return this.loadViews({ fetchAllViews })
    },
    async loadViews({ fetchAllViews = false } = {}) {
      if (this.loaded) return

      const api = apiStore().getApiClient
      const { data, auth } = await api.getViews(fetchAllViews)
      this.views = data
      this.currentView = this.views[0]
      this.canCreate = Boolean(auth.can.create)
      this.loaded = true

      this.subscribe()
    },
    getViewById(id: string): View | undefined {
      return this.views.find(i => i.id === id)
    },
    setCurrentView(viewId: string) {
      this.currentView = this.getViewById(viewId) || this.views[0] || {}
    },
    resetCurrentView() {
      this.currentView = null
    },
    setHighlightedEntityType(entity_type: string) {
      this.highlightedEntityType = entity_type
    },
    setDisplayType(displayType: 'tree' | 'graph' | 'table') {
      this.displayType = mapStringToDisplayType(displayType)
    },
    async subscribe() {
      const client = await this.getSocketClient

      client
        .private(VIEWS_CHANNEL)
        .listen(ViewsEvents.CREATED, (view: View) => {
          this.views.push(view)
        })
        .listen(ViewsEvents.UPDATED, (view: View) => {
          const index = this.views.findIndex(v => v.id === view.id)
          if (index !== -1) {
            this.views[index] = view
          }
        })
        .listen(ViewsEvents.DELETED, ({ id }: { id: string }) => {
          const index = this.views.findIndex(v => v.id === id)
          if (index !== -1) {
            this.views[index] = {
              ...this.views[index],
              deleted_at: new Date().toISOString(),
            }
          }
        })
    },
    async unsubscribe() {
      const client = await this.getSocketClient

      client.leave(VIEWS_CHANNEL)
    },
  },
})
