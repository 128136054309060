import { defineStore } from 'pinia'
// import { logEvent } from 'firebase/analytics'
import { userStore } from '../stores/user'

export const fbStore = defineStore({
  id: 'fb',
  state: () => ({
    analytics: {}, // getAnalytics(),
  }),

  // optional getters
  getters: {},
  // optional actions
  actions: {
    logEvent(event_name: any, other_params: any = {}) {
      const user = userStore().user
      const params: any = {
        uid: user?.id,
        first_name: user?.first_name,
        last_name: user?.last_name,
        client_name: user?.client_name,
      }
      const allParams = { ...params, ...other_params }
      // logEvent(this.analytics, event_name, allParams)
    },
  },
})
