<script setup lang="ts">
import { userStore } from '~/stores/user'
import { UserRoles } from '~/types/user'
import Dashboard from './dashboard.vue'
const { t } = useI18n()

const pageTitle = computed(() => {
  if (userStore().userHasRole(UserRoles.PROJECT_MANAGER, UserRoles.ADMIN)) {
    return t('global.boms', 2)
  } else if (userStore().userHasRole(UserRoles.CATALOG_MANAGER)) {
    return t('global.catalog', 2)
  } else if (userStore().userHasRole(UserRoles.SOURCING_MANAGER)) {
    return t('global.sourcing', 2)
  } else {
    return t('global.dashboard')
  }
})

const appIcon = computed(() => {
  if (userStore().userHasRole(UserRoles.PROJECT_MANAGER, UserRoles.ADMIN)) {
    return 'flow-connection'
  } else if (userStore().userHasRole(UserRoles.CATALOG_MANAGER)) {
    return 'inventory-management'
  } else if (userStore().userHasRole(UserRoles.SOURCING_MANAGER)) {
    return 'supplier'
  } else {
    return 'home'
  }
})
</script>

<template>
  <Page :title="pageTitle" :icon="appIcon">
    <template v-if="userStore().userHasRole(UserRoles.PROJECT_MANAGER, UserRoles.ADMIN)">
      <BomsList />
    </template>
    <template v-else-if="userStore().userHasRole(UserRoles.CATALOG_MANAGER)">
      <LibraryEntitiesList />
    </template>
    <template v-else-if="userStore().userHasRole(UserRoles.SOURCING_MANAGER)">
      <SuppliersList />
    </template>
    <template v-else>
      <Dashboard />
    </template>
  </Page>
</template>

<route lang="yaml">
meta:
  layout: home
  requiresAuth: true
</route>
