import { defineStore } from 'pinia'

export const orgStore = defineStore({
  // name of the store
  // it is used in devtools and allows restoring state
  id: 'org',
  // a function that returns a fresh state
  state: () => ({
    org: {
      clients: [] as Array<any>,
    },
  }),
  // optional getters
  getters: {
    getOrgs(): any {
      return this.org
    },
  },

  // optional actions
  actions: {
    getOrg(orgId: string): any {
      return this.org.clients.find(item => item.value === orgId)
    },

    async loadOrg(clients: any[]): Promise<any> {
      this.org.clients = clients
    },
  },
})
