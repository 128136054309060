<script lang="ts" setup>
import { debounce } from 'lodash'
import { apiStore } from '~/stores/api'
import { unwrapApiErrors } from '~/types/api'
import { ToastList } from '~/types/toast'
import { Entity } from '~/types/view-elements'

const props = defineProps({
  modelValue: {
    type: String,
    required: false,
  },
  isEditing: {
    type: Boolean,
    required: true,
    default: false,
  },
  isDiversity: {
    type: Boolean,
    default: false,
  },
})

const { t } = useI18n()
const api = apiStore().getApiClient

const data = reactive({
  entity: null as null | Entity,
})

const state = reactive({
  isLoading: false,
  search: {
    value: '',
    open: false,
    suggestions: [],
    loading: false,
  },
  toast: inject('toast') as ToastList,
})

const emit = defineEmits<{
  'update:modelValue': [string]
  'select-entity': [Entity]
}>()

const loadSearchSuggestions = debounce(async (search: string) => {
  if (!search) {
    state.search.suggestions = []
  } else {
    state.search.open = true
    state.search.loading = true
    try {
      const search = state.search.value.replace(/`/g, '%60')
      if (props.isDiversity) {
        const { data } = await api.searchDiversity(1, 20, search)
        state.search.suggestions = data
      } else {
        const { data } = await api.searchEntityByName(1, 20, search)
        state.search.suggestions = data
      }
    } catch (error) {
      const errorMessage = unwrapApiErrors(error)
      state.search.suggestions = []
      state.toast.error(t('global.error'), errorMessage)
    } finally {
      state.search.loading = false
    }
  }
}, 400)

function closeSearchSuggestions() {
  setTimeout(() => {
    state.search.open = false
  }, 200)
}

function selectEntity(entity: Entity | null) {
  if (!entity) {
    data.entity = null
    emit('update:modelValue', '')
    return
  }

  data.entity = entity

  emit('update:modelValue', entity.uuid)
  emit('select-entity', entity)

  state.search.value = entity.name
  state.search.open = false
}

async function loadData() {}

onMounted(() => {
  loadData()
})
</script>

<template>
  <div>
    <template v-if="!state.isLoading">
      <template v-if="data.entity">
        <div
          class="flex gap-2 bg-white border border-gray-300"
          :class="data.entity ? 'p-2.5 rounded-lg' : 'px-3 py-1 rounded'"
        >
          <div class="flex items-center">
            <EntityIcon :entity="data.entity" :tooltip="true" />
          </div>
          <div class="flex flex-col -gap-0.25">
            <div class="flex items-center gap-2">
              <h3 class="font-semibold text-gray-900 line-clamp-1 break-all" :title="data.entity.name">
                {{ data.entity.name }}
              </h3>
              <PublishingStatusTag
                v-if="data.entity.status"
                :status="data.entity.status"
                :reduced="true"
                class="!p-0.75"
              />
            </div>
            <p class="text-xs text-gray-500 break-all line-clamp-2">
              {{ data.entity.description }}
            </p>
          </div>
          <div v-if="props.isEditing" class="flex items-center ml-auto my-auto">
            <button @click="selectEntity(null)" class="p-1.5 text-red-400 hover:text-red-600">
              <div i="carbon-close" class="w-4 h-4" />
            </button>
          </div>
        </div>
      </template>
      <p v-else-if="!props.isEditing" class="text-gray-400 italic text-sm">{{ $t('instances.select_entity') }}</p>

      <div v-if="props.isEditing" class="relative" :class="{ 'pt-1': data.entity }">
        <OSearchBar
          v-model="state.search.value"
          class="!py-2"
          @update:modelValue="loadSearchSuggestions"
          @blur="closeSearchSuggestions"
        />
        <EntitySearchSuggestions
          :open="state.search.open"
          :suggestions="state.search.suggestions"
          :loading="state.search.loading"
          :highlight="state.search.value"
          :disableEntityCreateFallback="true"
          class="mt-1"
          @focusEntity="selectEntity"
        />
      </div>
    </template>

    <div v-else class="flex justify-center items-center w-full">
      <Loader />
    </div>
  </div>
</template>
