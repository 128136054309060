<script setup lang="ts">
import type { PropType } from 'vue'

/**
 * @type : String => 'spin' | 'fade'
 * (Type of loader)
 * (fade examples: https://flowbite.com/docs/components/skeleton/)
 *
 * @secondaryColor : Boolean
 * (If true, le loader will print with a white color)
 *
 * @size : String
 * (Size of the spin loader)
 */
const props = defineProps({
  type: {
    type: String as PropType<'spin' | 'fade'>,
    required: false,
    default: 'spin',
  },
  secondaryColor: {
    type: Boolean,
    required: false,
    default: false,
  },
  size: {
    type: String,
    required: false,
    default: 'h-10 w-10',
  },
})
</script>

<template>
  <div
    v-if="props.type === 'spin'"
    class="flex justify-center items-center"
    :class="[props.secondaryColor ? 'text-white' : 'text-secondary']"
  >
    <div i="carbon-fade" :class="`animate-spin ${props.size}`" />
    <span class="sr-only">{{ $t('global.loading') }}...</span>
  </div>

  <div v-else role="status" class="flex animate-pulse">
    <slot>
      <div class="h-2 w-full bg-gray-200 rounded-full dark:bg-gray-700" />
    </slot>

    <span class="sr-only">{{ $t('global.loading') }}...</span>
  </div>
</template>
