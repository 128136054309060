import { Tenants } from './environments'

export enum ETLTypes {
  FORVIA_EBOM_XLSX = 'forvia_ebom_xlsx',
  // FORVIA_EBOM_JSON = 'forvia_ebom_json',
  GENERIC_BOM = 'generic_bom',
  GENERIC_JSON = 'generic_json',
}

export const ETLTypesConfig: Record<ETLTypes, ETLConfig> = {
  [ETLTypes.FORVIA_EBOM_XLSX]: {
    tenant: Tenants.FORVIA,
    key: ETLTypes.FORVIA_EBOM_XLSX,
    payload_type: 'forvia-ebom',
    allowed_extensions: ['xlsx', 'xls'],
    fileType: 'XLSX',
    importType: 'bom',
  },
  // [ETLTypes.FORVIA_EBOM_JSON]: {
  //   tenant: Tenants.FORVIA,
  //   key: ETLTypes.FORVIA_EBOM_JSON,
  //   payload_type: 'forvia-ebom',
  //   allowed_extensions: ['json'],
  //   fileType: 'JSON',
  //   importType: 'bom',
  // },
  [ETLTypes.GENERIC_BOM]: {
    key: ETLTypes.GENERIC_BOM,
    payload_type: 'generic-bom',
    allowed_extensions: ['csv'],
    fileType: 'CSV',
    importType: 'bom',
  },
  [ETLTypes.GENERIC_JSON]: {
    key: ETLTypes.GENERIC_JSON,
    payload_type: 'generic',
    allowed_extensions: ['json', 'csv'],
    fileType: 'JSON, CSV',
    importType: 'catalog',
  },
}

export interface ETLConfig {
  tenant?: string
  key: ETLTypes
  payload_type: string
  allowed_extensions: string[]
  fileType: string
  importType: 'bom' | 'catalog'
}
