<script setup lang="ts">
import { SearchResult } from '~/stores/search'
import type { Entity } from '~/types/view-elements'

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  suggestions: {
    type: Array<SearchResult>,
    required: true,
  },
  loading: {
    type: Boolean,
    required: true,
  },
  highlight: {
    type: String,
    required: true,
  },
  limit: {
    type: Number,
    required: false,
    default: null,
  },
  class: {
    type: String,
    required: false,
    default: '',
  },
  disableEntityCreateFallback: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const emit = defineEmits<{
  'focus-entity': [entity: Entity]
  close: []
  'create-entity-from-suggestion': [name: string]
}>()

const highlight = computed(() => {
  return (text: string, search: string) => {
    if (!search) return text
    const regex = new RegExp(`(${search})`, 'gi')
    return text.replace(regex, '<span class="bg-blue-50 font-semibold">$1</span>')
  }
})

function goToEntity(entity: Entity) {
  emit('focus-entity', entity)
}

function createNewEntity(name: string) {
  emit('create-entity-from-suggestion', name)
}
</script>

<template>
  <div
    v-if="props.open"
    class="absolute flex w-full h-fit max-h-60vh md:max-h-[50vh] lg:max-h-40vh bg-white border border-gray-200 overflow-y-scroll rounded z-50"
    :class="props.class"
  >
    <div v-if="!props.loading" class="flex flex-col w-full">
      <button
        v-for="suggestion in props.suggestions"
        :key="suggestion.uuid"
        class="flex items-center justify-between w-full gap-2 py-2 px-4 not-last:border-b border-gray-200 transition hover:bg-gray-50"
        @click="goToEntity(suggestion)"
      >
        <div class="flex flex-1 gap-4 items-center text-start overflow-x-clip">
          <EntityIcon :entity="suggestion" :tooltip="true" />
          <div class="flex flex-col">
            <div class="flex items-center gap-2">
              <p
                v-if="suggestion?.name"
                class="text-gray-900 font-semibold line-clamp-1"
                v-html="highlight(suggestion.name, props.highlight)"
              />
              <PublishingStatusTag v-if="suggestion?.status" :status="suggestion?.status" />
            </div>
            <p
              v-if="suggestion?.description"
              class="text-gray-500 text-sm line-clamp-2"
              v-html="highlight(suggestion.description, props.highlight)"
            />
          </div>
        </div>
        <div>
          <p
            v-if="suggestion.reference"
            class="text-xs text-gray-500"
            v-html="highlight(suggestion.reference, props.highlight)"
          />
        </div>
      </button>
      <p v-if="props.suggestions.length === 0 && disableEntityCreateFallback" class="text-gray-400 text-xs p-2">
        {{ $t('entities.no_suggestions') }}
      </p>
      <button
        v-if="props.suggestions.length === 0 && !disableEntityCreateFallback"
        @click="createNewEntity(props.highlight)"
        class="flex items-center justify-between w-full gap-2 py-2 px-4 not-last:border-b border-gray-200 transition hover:bg-gray-50"
      >
        <div class="flex flex-1 gap-4 items-center text-start overflow-x-clip">
          <div class="p-1 rounded bg-primary text-white">
            <div i="carbon-add"></div>
          </div>
          <div class="flex flex-col">
            <div class="text-gray-900">{{ `Create an ${props.highlight} Entity` }}</div>
          </div>
        </div>
      </button>
    </div>
    <div v-else class="flex w-full my-2 justify-center">
      <Loader />
    </div>
  </div>
</template>
