import { GraphEdge, Position, Styles } from '@vue-flow/core'
import type { AuthPermissions } from './auth'
import { Diversity } from './diversity'

export enum SystemAttributeStatus {
  IsStandard = 'is_standard',
  IsOptional = 'is_optional',
  HasVariability = 'has_variability',
  HasWarningNoEntity = 'has_warning_no_entity',
  HasWarningNoVariability = 'has_warning_no_variability',
  HasWarningNoQuantity = 'has_warning_no_quantity',
}

export interface Instance {
  id: string
  entity_type_id: string
  name: string
  description: string
  quantity: number
  reference: null | string
  uom: string

  // this doesn't exist on the back end response anymore, but it is added when the requests lands
  type: string

  sync_key: string | null

  bom_id: string
  view_id: string
  parent_id: string | null

  entity_type: EntitySchema
  custom_attributes: Array<Attribute>
  variabilities: Array<VariabilityConfiguration>
  system_attributes?: {
    statuses?: string[]
  }

  created_at: string
  updated_at: string

  auth: InstanceAuth

  status: BomReadinessStatus

  // These properties are added by the Graph
  changes?: OverlayChange[]
  meta?: GraphElementMeta
}

export type CreateInstanceSchema = Omit<
  Instance,
  'id' | 'sync_lock' | 'entity_type' | 'system_attributes' | 'changes' | 'created_at' | 'updated_at' | 'auth' | 'meta'
>

export type UpdateInstanceSchema = Partial<
  Omit<
    Instance,
    | 'id'
    | 'entity_type_id'
    | 'sync_lock'
    | 'bom_id'
    | 'view_id'
    | 'entity_type'
    | 'variabilities'
    | 'system_attributes'
    | 'created_at'
    | 'updated_at'
    | 'changes'
    | 'auth'
    | 'meta'
  >
>

export interface UpdateInstanceVariabilitiesSchema {
  variabilities: Omit<VariabilityConfiguration, 'id' | 'variability' | 'entity'>[]
}

export interface VariabilityConfiguration {
  entity: Entity | null
  entity_id: string
  id: string
  status: BomReadinessStatus
  variability_id: string
  variability: Diversity | null
}

export interface InstanceTodo {
  id: string
  description: string
  priority_level: number
}

export interface TreeViewInstance {
  id: string
  label: string
  group: string
  description: string
  quantity: number
  status: BomReadinessStatus
  reference: null | string
  uom: string

  custom_attributes: Array<Attribute>
  system_attributes: {
    entity_type_id: string
    statuses?: string[]
    styling?: {
      color: string
      icon: string
    }
    variability_configurations?: VariabilityConfiguration[]
  }

  created_at: string
  updated_at: string

  auth: AuthPermissions

  // TODO - add meta type
  meta?: GraphElementMeta

  todo: null | InstanceTodo

  changes?: OverlayChange[]
}

export interface TableViewInstance {
  id: string
  name: string
  // not sure about this one
  label?: string
  level: number
  cellDataType: string
  description: string
  reference: string

  image: string
  status: string

  system_attributes: {
    entity_type_id: string | null
    statuses?: string[]
    styling?: {
      color: string
      icon: string
    }
    variability_configurations?: Array<string>
  }

  auth: AuthPermissions

  // TODO - add meta type
  meta?: GraphElementMeta
  // TODO - add todo type
  todo: null | unknown[]
}

export type EntityWithOptionalType = Entity | Instance | TreeViewInstance | Diversity | Bom

export enum GraphNodeType {
  VariantEntity = 'variant-entity',
  BaseEntity = 'base-entity',
}

interface GraphNodePosition {
  x: number
  y: number
}

type GraphNodeEvents = Record<string, unknown>

export interface GraphNodeData {
  id: string
  data: TreeViewInstance
  label: string
  description: string
  selected: boolean
  sourcePosition: Position
  targetPosition: Position
  value: string
  type: GraphNodeType
  position: GraphNodePosition
  events: GraphNodeEvents
}

type GraphEdgeEvents = Record<string, unknown>

export interface EdgeWithRelatedEdges extends Edge {
  relatedEdges: {
    edges: Edge[]
    position: Edge
  }
}

export interface GraphEdgeData {
  id: string
  source: string
  target: string
  type: string
  selected: boolean
  selectable: boolean
  label: string | undefined
  animated: boolean
  style: (edge?: GraphEdge<Edge>) => Styles
  data: EdgeWithRelatedEdges
  events: GraphEdgeEvents
}

export type EdgeLibraryType = Record<string, Map<number, Edge>>

export interface VueFlowProps {
  nodes: GraphNodeData[]
  edges: GraphEdgeData[]
}

export interface GraphData {
  nodes: TreeViewInstance[]
  edges: Edge[]
}

// Deprecated
export interface EntitySchema {
  id: string
  name: string
  display_name: string
  color: string | null
  icon: string | null
  description: string | null
  slug: string

  bom_attributes: Attribute[]

  catalog_attributes: Attribute[]

  parent: Omit<EntitySchema, 'bom_attributes' | 'catalog_attributes'> | null
  parent_id: string | null

  created_at: string
  updated_at: string | null
  deleted_at: string | null

  auth: EntityTypeAuth
}

export interface CreateEntitySchema
  extends Omit<
    EntitySchema,
    | 'id'
    | 'display_name'
    | 'slug'
    | 'parent'
    | 'created_at'
    | 'updated_at'
    | 'deleted_at'
    | 'auth'
    | 'catalog_attributes'
    | 'bom_attributes'
  > {
  bom_attributes: string[]
  catalog_attributes: string[]
}

export interface UpdateEntitySchema
  extends Partial<
    Omit<EntitySchema, 'created_at' | 'updated_at' | 'deleted_at' | 'catalog_attributes' | 'bom_attributes'>
  > {
  bom_attributes?: string[]
  catalog_attributes?: string[]
}

export enum EntityTypeAttributeType {
  Catalog = 'catalog',
  Bom = 'bom',
}

export interface AttributeAuth {
  can: {
    view: boolean
    update: boolean
    delete: boolean
  }
}

export interface AttributeAuth {
  can: {
    view: boolean
    update: boolean
    delete: boolean
  }
}

export interface EntityTypeAuth {
  can: {
    view: boolean
    update: boolean
    delete: boolean
  }
}

export interface InstanceAuth {
  can: {
    view: boolean
    update: boolean
    comment: boolean
    delete: boolean
  }
}

export interface Attribute {
  id: string
  name: string
  slug: string
  type: 'STRING' | 'NUMBER' | 'BOOLEAN' | 'DATE' | 'SELECT' | 'MULTISELECT' | 'LIST'
  value: null | string | number | boolean | Array<string>
  attribute_id: string
  options?: string[]

  // deprecated
  created_at: string
  updated_at: string
  deleted_at: string | null
}

export type CreateAttribute = Omit<Attribute, 'created_at' | 'id' | 'updated_at'>

export type UpdateAttribute = Partial<Omit<Attribute, 'created_at' | 'id' | 'updated_at'>>

export enum OverlayChangeType {
  add = 'add',
  update = 'update',
  delete = 'delete',
  add_attribute = 'add_attribute',
  update_attribute = 'update_attribute',
  delete_attribute = 'delete_attribute',
  update_variability = 'update_variability',
  add_variability = 'add_variability',
  delete_variability = 'delete_variability',
}

export interface GraphElementMeta {
  cx_is_new?: boolean
  cx_was_new?: boolean
  source_id?: string
}

export interface OverlayChange {
  type: OverlayChangeType

  // type: add
  source_id?: string

  // type: update
  field?: string

  // type: add_attribute, delete_attribute
  attribute_id?: string
  value?: string | number | boolean | Array<string>

  // type: update, update_attribute
  old_value?: string | number | boolean | Array<string> | null
  new_value?: string | number | boolean | Array<string> | null

  // type: update_variability, add_variability
  variability_id?: string
  variability?: Diversity

  // type: update_variability
  old_entity_id?: string | null
  old_entity?: Entity | null
  new_entity_id?: string
  new_entity?: Entity | null

  // type: add_variability
  entity_id?: string | null
  entity?: Entity | null
}

export interface MinimalEntity {
  id: string
  name: string
  reference: string
  schema_type: string | null
}

// Refers to CachedEntity on the back end - Catalog items
export interface Entity {
  id: string
  uuid: string
  name: string
  description: string
  entity_type_id: string
  image: string
  attachment_id: string
  attributes: Attribute[]
  status: string
  parent_id?: string | null
  reference: string | null

  entity_type: EntitySchema

  meta?: {
    cx_is_new?: boolean
  }

  system_attributes?: {
    styling?: {
      icon: string | null
      color: string | null
    }
    cx_variability_configurations?: VariabilityConfiguration[]
    cx_has_variability?: boolean
    cx_configurations?: Array<{
      id: string
      name: string
      reference: string
    }>
  }

  deleted_at: string | null
  created_at: string
  updated_at: string

  auth: AuthPermissions & { create_revision: boolean }
}

export type CreateEntity = Partial<Omit<Entity, 'id' | 'uuid' | 'created_at' | 'updated_at' | 'deleted_at' | 'slug'>>

export type UpdateEntity = Partial<Omit<Entity, 'id' | 'uuid' | 'created_at' | 'updated_at' | 'deleted_at' | 'slug'>>

export interface EdgeParams {
  id?: number
  source_id: string
  target_id: string
  label: string
  attributes: unknown[]
  project_uuid: string
  view_id?: string
  push?: boolean
  variability_id?: string
}

// TODO change typing to instances edges
export interface Edge {
  id: number
  from: string
  to: string
  label: string
  meta?: GraphElementMeta
}

export interface GraphUpdatesBuffer {
  type: '' | 'add' | 'update' | 'delete' | 'multiple' | 'import'
  add: GraphData
  update: GraphData
  delete: GraphData
  import: GraphData
}

export interface PreviewSyncBomResponse {
  sync_preview_id: string
  bom_id: string
  source_view_id: string
  destination_view_id: string
  nodes?: GraphData['nodes']
  edges?: GraphData['edges']
  cache: {
    from_cache: boolean
    ttl: number
  }
  'auto-sync': {
    enabled: boolean
    id: string | null
  }
}

export interface CreateBomSyncRequest {
  bom_id: string
  source_view_id: string
  destination_view_id: string
}

export interface SyncBomResponse {
  message: string
}

export interface SyncBomRequest {
  sync_preview_id: string
  bom_id: string
  source_view_id: string
  destination_view_id: string
  excluded_instances: string[]
  enable_auto_sync: boolean
}

export interface BomReadinessHistory {
  views: string[]
  summary: Summary[]
}

export interface Summary {
  total_instances: number
  total_not_started: number
  total_in_progress: number
  total_done: number
  total_warnings: number
  date: string
}

export interface View {
  id: string
  name: string
  entity_types: string[]
  relationships: string[]
  updated_at: string | null
  created_at: string
  deleted_at: string | null
  auth: {
    can: {
      view: boolean
      update: boolean
      delete: boolean
    }
  }
}

export type CreateView = Omit<View, 'id' | 'created_at' | 'updated_at' | 'deleted_at'>

export type UpdateView = Partial<Omit<View, 'id' | 'created_at' | 'updated_at' | 'deleted_at'>> & { id: string }

export enum DisplayType {
  Tree = 'tree',
  Graph = 'graph',
  Table = 'table',
}

export function mapStringToDisplayType(displayType: string): DisplayType {
  switch (displayType) {
    case 'tree':
      return DisplayType.Tree
    case 'graph':
      return DisplayType.Graph
    case 'table':
      return DisplayType.Table
    default:
      return DisplayType.Tree
  }
}

export enum BomStatus {
  Draft = 'draft',
  Published = 'published',
}

export interface Bom {
  id: string
  name: string
  description: string
  reference: string
  status: BomStatus
  image: string

  variability_configurations: Diversity[]

  created_at: string
  updated_at: string

  auth: {
    can: {
      comment: boolean
      create: boolean
      create_revision: boolean
      delete: boolean
      share: boolean
      update: boolean
      view: boolean
    }
  }
}

export type CreateBom = Omit<Bom, 'id' | 'created_at' | 'updated_at' | 'auth'>

export enum RevisionStatus {
  Draft = 'draft',
  Published = 'published',
  archived = 'archived',
  approved = 'approved',
  rejected = 'rejected',
  canceled = 'canceled',
}

export interface BomRevision {
  id: string
  name: string
  parent_id: string | null
  status: RevisionStatus
  created_at: string
}

export interface BomReadiness {
  view: {
    id: string
    name: string
  }
  total_instances: number
  statuses: {
    not_started: number
    in_progress: number
    done: number
  }
}

export interface BomWarning {
  bom_id: string
  view_id: string
  total_instances: number
  total_warnings: number
}

export interface CreateBomRevision {
  id: string
  name: string
  status: 'Adding'
}

export interface Supplier extends Omit<Entity, 'schema_type'> {
  schema_type: 'Supplier'
}

// Define the Details interface
export interface Details {
  bomName: string
  status: string
  createdDate: string
  lastUpdatedDate: string
  description: string
  image: string
  completion: number
}

export interface Document {
  id: string
  name: string
  description: string
  tags: string[]

  current_version: {
    version: number
    document_id: string
    download_id: string
    download_url: string
    id: string
    mime_type: string
    path: string
    user: string
    created_at: string
  }

  created_at: string
  updated_at: string
}

export enum BomReadinessStatus {
  NotStarted = 'not_started',
  InProgress = 'in_progress',
  Done = 'done',
}

export interface MeasurementUnits {
  centiliter: string
  centimeter: string
  square_centimeter: string
  cubic_centimeter: string
  gram: string
  kilogram: string
  kilometer: string
  square_kilometer: string
  cubic_kilometer: string
  liter: string
  meter: string
  square_meter: string
  cubic_meter: string
  milligram: string
  milliliter: string
  millimeter: string
  square_millimeter: string
  cubic_millimeter: string
  piece: string
  percentage: string
}

export interface KnowledgeFilters {
  [key: string]: {
    name: string,
    enabled: boolean;
  };
}
