export function formatAttributes(attributes: any) {
  if (!attributes) return []

  if (Array.isArray(attributes)) return attributes

  return Object.keys(attributes).map(key => {
    return {
      name: key,
      slug: key,
      value: attributes[key],
    }
  })
}

export function isValidAttribute(name: string) {
  const BLACK_LISTED_NAMES = ['Id', 'Name', 'Description', 'Created At', 'Updated At', 'Deleted At', 'Status']
  const BLACK_PREFIXES = ['cx_']

  if (BLACK_LISTED_NAMES.includes(name)) return false

  for (let i = 0; i < BLACK_PREFIXES.length; i += 1) {
    if (name.startsWith(BLACK_PREFIXES[i])) return false
  }

  return true
}
