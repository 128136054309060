import { defaultConfig, plugin } from '@formkit/vue'
import { generateClasses } from '@formkit/tailwindcss'
import { de, fr } from '@formkit/i18n'
import { createProPlugin, inputs } from '@formkit/pro'
import * as carbonIcons from '@iconify-json/carbon'
import { applicationIcons } from '@formkit/icons'
import { createAutoAnimatePlugin, createAutoHeightTextareaPlugin } from '@formkit/addons'
import type { UserModule } from '~/types'
import { formkitTheme } from '~/modules/formkit-theme'
import { getEnv } from '~/config'

const formatedCarbonIcons = Object.keys(carbonIcons.icons.icons).reduce((acc: any, key: any) => {
  acc[`carbon-${key}`] = `<svg viewBox='0 0 32 32'>${carbonIcons.icons.icons[key].body}</svg>`
  return acc
}, {})

const pro =
  getEnv() === 'production' ? createProPlugin('fk-6100abc1a1', inputs) : createProPlugin('fk-a423e3511e', inputs)

function getNavigatorLanguage() {
  let language = 'en'
  if (typeof window !== 'undefined') {
    language = navigator.language.substring(0, 2)
    if (language !== 'de' && language !== 'fr') language = 'en'
  }
  return language
}

// Configure FormKit:
const config = defaultConfig({
  config: {
    classes: generateClasses(formkitTheme),
  },
  icons: {
    ...applicationIcons,
    ...formatedCarbonIcons,
  },
  locales: { de, fr },
  locale: getNavigatorLanguage(),
  plugins: [
    // @ts-expect-error
    createAutoHeightTextareaPlugin(),
    // @ts-expect-error
    createAutoAnimatePlugin(),
    pro,
  ],
})

export const install: UserModule = ({ isClient, initialState, app }) => {
  app.use(plugin, config)
}
