<script setup lang="ts">
const props = defineProps<{
  kind: string
  outline?: boolean
  label?: string
  icon?: string
}>()

const btnClass = `btn${props.outline ? '-outline' : ''}-${props.kind}`
</script>

<template>
  <button class="flex flex-row gap-1 disabled:cursor-not-allowed" :class="[btnClass]">
    <div v-if="icon" :i="`carbon-${icon}`" />
    <slot v-if="$slots.default" />
    <template v-else>{{ label }}</template>
  </button>
</template>
