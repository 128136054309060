import { defineStore } from 'pinia'
import { apiStore } from './api'
import { ClientSettings } from '~/types/configs'
import { viewsStore } from './views'

export const configStore = defineStore({
  id: 'config',
  state: () => ({
    client: {
      settings: {
        app_client_id: '',
        cognyx_client_id: '',
        variability: {
          variability_entity_node_label: 'ProductConfiguration',
          variability_entity_edge_label: 'HAS',
        },
        pagination: {
          per_page: 50,
        },
        project: {
          schema_type: 'Project',
        },
        supplier: {
          schema_type: 'Supplier',
        },
        graph: {
          entity_project_relationship_name: 'BELONGS_TO_PROJECT',
          entity_possible_supplier_relationship_name: 'CAN_BE_SUPPLIED_BY',
          entity_actual_supplier_relationship_name: 'IS_SUPPLIED_BY',
        },
        protected: {
          entity_types: ['Project', 'Supplier'],
          relationships: ['BELONGS_TO_PROJECT', 'CAN_BE_SUPPLIED_BY', 'IS_SUPPLIED_BY'],
          attributes: ['quantity'],
          revisions: {
            disallowed: [],
          },
          locked_views: [],
        },
        features: {
          catalog: {
            enabled: true,
            files_enabled: true,
          },
          projects: {
            enabled: true,
            files_enabled: true,
            bom: {
              default_type: 'tree',
              default_view: '',
              default_percentage_view: '',
            },
          },
          sourcing: {
            enabled: true,
            files_enabled: true,
          },
          reports: {
            enabled: true,
          },
          boms: {
            files_enabled: false,
            revisions_enabled: true,
            default_type: 'tree',
            default_view: '',
            diversity: {
              read_only: false,
            },
            locked_views: [],
          },
        },
      } as ClientSettings,
    },
    user: {
      views: {
        extendChildrenAutoConfirm: false,
      },
    },
  }),

  getters: {
    getExtendChildrenAutoConfirm(): boolean {
      return this.user.views.extendChildrenAutoConfirm
    },
    getProtectedEntityTypes(): string[] {
      return this.client.settings.protected.entity_types
    },
    getLockedView(): string[] {
      return this.client.settings.protected.locked_views
    },
    getClientDefaultViewDisplayType(): 'tree' | 'graph' | 'table' {
      return this.client.settings.features.projects.bom.default_type || 'tree'
    },
    getClientDefaultView(): string {
      const defaultView = viewsStore().getViews.length > 0 ? viewsStore().getViews[0].id : ''
      return this.client.settings.features.projects.bom.default_view || defaultView
    },
    getClientDefaultPercentageView(): string {
      return this.client.settings.features.projects.bom.default_percentage_view || ''
    },
    getBomRevisionsEnabled(): string[] {
      return this.client.settings.features.boms.revisions_enabled
    },
  },

  actions: {
    async loadClientSettings(): Promise<void> {
      const api = apiStore().getApiClient
      const settings: ClientSettings = await api.getClientSettings()
      this.client.settings = settings
    },
  },
})
