import { join } from 'lodash'
import { es, fr, zhCN } from 'date-fns/locale'

export function hydrate(thisInstance: any, object: any) {
  for (const [key] of Object.entries(thisInstance)) {
    if (object[key] != null && object[key] !== undefined) thisInstance[key] = object[key]
  }
}

export function removeInstance(instanceObject: any) {
  const { ...object } = instanceObject
  return object
}

export function capitalizeFirstLetter(string: String) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

// TODO : Local date instead of fr-FR
// options : https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
export function formatDate(timestamp: any, options: any = { dateStyle: 'long', timeStyle: 'short' }) {
  const local = navigator.language
  if (timestamp) {
    try {
      const formattedDate = new Intl.DateTimeFormat('local', {
        ...options,
      }).format(new Date(timestamp))
      return capitalizeFirstLetter(formattedDate)
    } catch (error) {
      return timestamp
    }
  }
  return ''
}

// TODO : Local date instead of fr-FR
export function formatShortDate(timestamp: any) {
  const local = navigator.language
  if (timestamp) {
    try {
      const formattedDate = new Intl.DateTimeFormat(local, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      }).format(new Date(timestamp))
      return capitalizeFirstLetter(formattedDate)
    } catch (error) {
      return timestamp
    }
  }
  return ''
}

export function createURLFromArray(arr: Array<string>) {
  const url = `/${join(arr, '/')}`
  return url
}

export function truncateString(str: string, length: number) {
  if (str.length > length) return `${str.substring(0, length)}...`
  return str
}

export function formatStringToCamelCase(str: string) {
  return str
    .split(/[^\p{L}\d]/gu)
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join('')
}

export function formatStringToSnakeCase(str: string) {
  return str
    .replace(/[^\p{L}\d]/gu, '_') // Replace all non-alphanumeric characters with an underscore
    .replace(/_{2,}/g, '_') // Remove duplicate underscores
    .replace(/^_|_$/g, '') // Remove leading/trailing underscores
    .normalize('NFD')
    .replace(/[\u0300-\u036F]/g, '') // Remove diacritical marks
    .toLowerCase()
}

export function formatString(value: any, type: any, cell_text_max_length = 20) {
  let output = value

  if (Array.isArray(value)) {
    output = value.join(', ')

    if (output.length > cell_text_max_length) output = `${output.substring(0, cell_text_max_length)}...`
  }
  if (type === 'string' && output?.length > cell_text_max_length)
    output = `${output.substring(0, cell_text_max_length)}...`

  return output
}

/**
 * Normalizes a string to replace non-alphanumeric and diacritical marks with
 * plain equivalents.
 * Based on https://stackoverflow.com/a/37511463/772859
 */
export function normalizeString(input: string, spaceReplacer = '_'): string {
  return (input || '')
    .normalize('NFD')
    .replace(/[\u0300-\u036F]/g, '')
    .toLowerCase()
    .replace(/[!"£$%^&*()+[\]{};:@#~?\\/,|><`¬'=‘’§.]/g, '')
    .replace(/\s+/g, spaceReplacer)
}

export function getNavigatorLanguage() {
  let language = 'en'
  if (typeof window !== 'undefined') language = navigator.language.substring(0, 2)
  return language
}

export const getDateFnsLocaleFromBrowserLanguage = () => {
  const locale = getNavigatorLanguage()
  switch (locale) {
    case 'es':
      return es
    case 'fr':
      return fr
    case 'zh':
      return zhCN
    default:
      return undefined
  }
}
