<script setup lang="ts">
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue'

/**
 * @open : Boolean
 * (The state of the drawer, works in pair with the @close event)
 */

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  maxWidth: {
    type: Number,
    required: false,
    default: 616,
  },
  zIndex: {
    type: Number,
    required: false,
    default: 9999,
  },
  dismissOnEscape: {
    type: Boolean,
    required: false,
    default: true,
  },
})

const { escape } = useMagicKeys()

const emit = defineEmits<{
  (e: 'close'): void
}>()

function closeModal() {
  emit('close')
}

watchEffect(() => {
  if (escape.value && props.dismissOnEscape) {
    closeModal()
  }
})
</script>

<template>
  <TransitionRoot as="modalTemplate" :show="props.open">
    <!-- Deactivated close event because of an issue in which closing sibling Modals would not close the right one -->
    <Dialog as="modalDiv" class="fixed inset-0" :style="{ zIndex: props.zIndex }" @close="() => {}">
      <div className="fixed inset-0 overflow-hidden">
        <div
          class="flex min-h-full h-full items-center sm:items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="modalTemplate"
            enter="ease-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in duration-200"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" @click="closeModal()" />
          </TransitionChild>
          <!-- This element is to trick the browser into centering the modal contents. -->
          <span class="hidden sm:inline-block align-middle sm:h-screen" aria-hidden="true"> &#8203; </span>
          <TransitionChild
            as="modalTemplate"
            enter="ease-out duration-300"
            enter-from="opacity-0 sm:scale-95"
            enter-to="opacity-100 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 sm:scale-100"
            leave-to="opacity-0 sm:scale-95"
            class="max-h-full"
          >
            <div
              class="inline-block max-h-[95vh] p-6 w-[80vw] bg-white rounded-lg text-left shadow-xl transform transition-all"
              :style="{ maxWidth: `${props.maxWidth}px` }"
            >
              <slot name="content" />
              <div v-if="$slots.footer" class="flex mt-6">
                <slot name="footer" />
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
