<script setup lang="ts">
import { v4 as uuidv4 } from 'uuid'
import { useTooltip } from '~/composables/useTooltip'

const props = defineProps({
  status: {
    type: String as PropType<
      'draft' | 'published' | 'archived' | 'approved' | 'rejected' | 'canceled' | 'not_started' | 'in_progress' | 'done'
    >,
    required: true,
  },
  reduced: {
    type: Boolean,
    required: false,
    default: false,
  },
  class: {
    type: String,
    required: false,
    default: '',
  },
})

const { t } = useI18n()

const state = reactive({
  randUuid: uuidv4(),
})

const getStatusIcon = computed(() => {
  if (props.status === 'draft') return 'carbon-edit'
  if (props.status === 'published') return 'carbon-checkmark'
  if (props.status === 'approved') return 'carbon-checkmark'
  if (props.status === 'rejected') return 'carbon-close'
  if (props.status === 'archived') return 'carbon-close'
  if (props.status === 'not_started') return '/assets/icons/status-not-started.svg'
  if (props.status === 'in_progress') return '/assets/icons/status-in-progress.svg'
  if (props.status === 'done') return '/assets/icons/status-done.svg'
  return ''
})

const tooltipContent = computed(() => {
  return ['not_started', 'in_progress', 'done'].includes(props.status)
    ? t(`global.${props.status}`)
    : t(`global.status_${props.status}`)
})

const tooltip = useTooltip({
  selector: `#publishing-status-tag-${state.randUuid}`,
  content: tooltipContent.value,
})

function formatStatus(status: string): string {
  return status.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())
}
</script>

<template>
  <div
    :id="`publishing-status-tag-${state.randUuid}`"
    class="flex items-center h-fit gap-1.5 text-xs rounded"
    :class="[
      props.class,
      props.reduced ? 'p-0.5' : 'px-1.5 py-0.25',
      {
        'bg-gray-200 text-gray-500': props.status === 'draft',
        'bg-green-50 text-green-900': props.status === 'published' || props.status === 'approved',
        'bg-red-25 text-red-700': props.status === 'canceled' || props.status === 'rejected',
      },
    ]"
    @mouseover="tooltip.setUp"
    @mouseleave="tooltip.destroy"
  >
    <template v-if="getStatusIcon">
      <img v-if="getStatusIcon.endsWith('.svg')" :src="getStatusIcon" class="h-3 w-3" />
      <div v-else :i="getStatusIcon" class="h-3 w-3" />
    </template>

    <span v-if="!props.reduced && status">
      <CText size="s" weight="semibold" muted>{{ formatStatus(props.status) }}</CText>
    </span>
  </div>
</template>
