// import StackdriverErrorReporter from 'stackdriver-errors-js'
import { fbStore } from '~/stores/fb'

// export const errorHandler = new StackdriverErrorReporter()
// errorHandler.start(LOGGER_CONFIG)
export class LoggerHelper {
  logError = async (event: string, msg: string) => {
    // eslint-disable-next-line no-console
    console.log(`${event} ${msg}`)
    // errorHandler.report(`${event} msg`)
  }

  logEvent = async (event: string, params: any = {}) => {
    params.type = 'info'
    fbStore().logEvent(event, params)
  }
}
