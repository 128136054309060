import { signInWithEmailAndPassword } from 'firebase/auth'
import { fbStore } from '~/stores/fb'
import { fb_auth, on_auth_state_changed } from '~/services/firebase'
import { loadStores } from '~/controllers/stores'
import { userStore } from '~/stores/user'
let unsubscribe: any = null

export async function loadUser(): Promise<any> {
  fbStore().logEvent('loadUser')
  unsubscribe = on_auth_state_changed(fb_auth, async (user: any) => {
    if (user) {
      const userId: string = user?.uid ? user?.uid : ''
      await loadStores(userId)
    } else {
      userStore().setStoresLoadingState()
    }
  })
}

export function getUserId() {
  fbStore().logEvent('getUserId')
  const user = fb_auth.currentUser
  if (user) {
    const userId: string = user?.uid ? user?.uid : ''
    return userId
  }
  return ''
}

export async function signIn(email: string, password: string) {
  fbStore().logEvent('signIn')

  await signInWithEmailAndPassword(fb_auth, email, password)
  await loadUser()
}
export async function logout() {
  unsubscribe()
  fbStore().logEvent('logout')
  await fb_auth.signOut()
}
