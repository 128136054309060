import { onMounted, onUnmounted } from 'vue'

type KeyupCallback = (e: KeyboardEvent) => void

export function useKeyupHandler(callback: KeyupCallback, key = 'Escape') {
  const keyupHandler = (e: KeyboardEvent) => {
    if (e.key === key) {
      callback(e)
    }
  }

  onMounted(() => {
    window.addEventListener('keyup', keyupHandler)
  })

  onUnmounted(() => {
    window.removeEventListener('keyup', keyupHandler)
  })
}
