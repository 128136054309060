import _ from 'lodash'

export const formkitTheme = {
  // Global styles apply to _all_ inputs with matching section keys
  global: {
    fieldset: 'w-full border border-gray-300 rounded px-2 pb-1',
    help: 'text-xs text-gray-400 italic mt-1',
    inner:
      'relative focus-within:ring-blue-500 focus-within:ring-2 formkit-disabled:bg-gray-200 formkit-disabled:cursor-not-allowed formkit-disabled:pointer-events-none',
    input:
      'rounded appearance-none text-s text-gray-900 bg-transparent placeholder:text-gray-300 disabled:cursor-not-allowed focus:outline-none focus:ring-0 focus:shadow-none disabled:bg-gray-50',
    label: 'block mb-2 text-gray-900 text-s text-left',
    legend: 'font-bold text-sm',
    loaderIcon: 'inline-flex items-center w-4 text-gray-600 animate-spin',
    message: 'text-red-500 mb-1 text-xs italic',
    messages: 'list-none p-0 mt-1 mb-0',
    outer: 'formkit-disabled:opacity-50',
    prefixIcon:
      'flex self-stretch grow-0 shrink-0 pl-3 rounded-tl rounded-bl bg-white cursor-pointer [&>svg]:w-full [&>svg]:max-w-[1.1rem] [&>svg]:max-h-[1.1rem] [&>svg]:my-auto [&>svg]:text-gray-300',
    suffixIcon:
      'absolute right-0 top-1/2 -translate-y-1/2 pointer-events-none items-center justify-center w-7 mr-1 flex self-stretch grow-0 shrink-0 cursor-pointer [&>svg]:w-full [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto [&>svg]:hover:text-blue-500',
  },

  // Family styles apply to all inputs that share a common family
  'family:box': {
    decorator:
      'block relative h-4 w-4 rounded bg-white ring-1 ring-gray-100 peer-checked:ring-blue-500 text-transparent peer-checked:text-blue-500',
    decoratorIcon: 'flex p-0.5 w-full h-full absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2',
    help: 'mb-1.5 mt-1.5',
    input: 'fixed left-[999em] w-0 h-0 overflow-hidden opacity-0 pointer-events-none peer',
    label: '$reset text-sm text-gray-700 select-none',
    wrapper: 'flex items-center gap-2',
    outer: '$reset flex flex-col',
  },
  'family:button': {
    input: '$reset inline-flex items-center bg-blue-500 text-white text-sm font-normal py-3 px-6 rounded',
    wrapper: 'mb-1',
    prefixIcon: '$reset block w-4 -ml-2 mr-2 stretch',
    suffixIcon: '$reset block w-4 ml-2 stretch',
  },
  'family:dropdown': {
    dropdownWrapper: 'my-2 w-full drop-shadow-lg rounded [&::-webkit-scrollbar]:hidden',
    inner:
      'relative flex ring-1 ring-gray-100 focus-within:ring-2 rounded formkit-disabled:focus-within:ring-gray-100 formkit-disabled:focus-within:ring-1 [&>span:first-child]:focus-within:text-blue-500',
    input: 'w-full p-2 text-gray-600',
    listbox: 'bg-white drop-shadow-lg rounded overflow-hidden',
    listboxButton: 'flex w-12 self-stretch justify-center mx-auto',
    listitem:
      'relative hover:bg-gray-300 data-[is-active="true"]:bg-gray-300 data-[is-active="true"]:aria-selected:bg-blue-500 aria-selected:bg-blue-500 aria-selected:text-white',
    loaderIcon: 'ml-auto',
    loadMoreInner:
      'flex items-center justify-center text-sm p-2 text-center w-full text-blue-500 formkit-loading:text-gray-500 cursor-pointer [&>span]:mr-3 [&>span]:ml-0',
    option: 'p-2 text-s line-clamp-1',
    optionLoading: 'text-gray-500',
    placeholder: 'p-2 text-gray-300',
    selector: 'flex w-full justify-between items-center [&u]',
    selectedIcon: 'block absolute top-1/2 right-0 mr-2 w-3 -translate-y-1/2',
    selectIcon: 'flex box-content w-4 px-2 self-stretch grow-0 shrink-0',
  },
  'family:text': {
    inner:
      'flex items-center ring-1 ring-gray-100 focus-within:ring-2 [&>label:first-child]:focus-within:text-blue-500 rounded',
    input: 'w-full p-2 border-none text-base text-gray-700 placeholder-gray-300',
  },

  // Specific styles apply only to a given input type
  color: {
    inner:
      'flex max-w-[5.5em] w-full formkit-prefix-icon:max-w-[7.5em] formkit-suffix-icon:formkit-prefix-icon:max-w-[10em]',
    input:
      '$reset appearance-none w-full cursor-pointer border-none rounded p-0 m-0 bg-transparent [&::-webkit-color-swatch-wrapper]:p-0 [&::-webkit-color-swatch]:border-none',
    suffixIcon: 'min-w-[2.5em] pr-0 pl-0 m-auto',
  },
  datepicker: {
    inner:
      'relative flex ring-1 ring-gray-100 focus-within:ring-2 rounded formkit-disabled:focus-within:ring-gray-100 formkit-disabled:focus-within:ring-1 [&>span:first-child]:focus-within:text-blue-500',

    // Hotfix for double calendar
    calendar: 'last:hidden !first:block',

    panelWrapper:
      'absolute top-[calc(100%_+_0.5em)] drop-shadow-[0_0_1.25em_rgba(0,0,0,.25)] rounded-md p-5 bg-white z-10',
    panelHeader: 'grid grid-cols-[2.5em_1fr_2.5em] justify-center items-center border-b-2 mb-4 pb-4',
    input: 'w-full p-2 border-none text-base text-gray-700 placeholder-gray-300 selection:bg-blue-500',
    monthsHeader: 'flex items-center justify-center col-start-2 col-end-2',
    timeHeader: 'flex items-center justify-center col-start-2 col-end-2',
    overlayPlaceholder: 'text-gray-400',
    months: 'flex flex-wrap',
    month:
      'flex items-center justify-center w-[calc(33%_-_1em)] m-2 p-2 rounded-md bg-gray-200 aria-selected:bg-blue-500 aria-selected:text-white focus:outline focus:outline-2 focus:outline-blue-500 focus:outline-offset-2 focus:bg-white focus:text-black data-[is-extra=true]:opacity-25 formkit-disabled:opacity-50 formkit-disabled:cursor-default formkit-disabled:pointer-events-none',
    yearsHeader: 'flex items-center justify-center col-start-2 col-end-2',
    years: 'flex flex-wrap max-w-[35em]',
    year: ' flex items-center justify-center w-[calc(20%_-_1em)] m-2 p-2 rounded-md bg-gray-200 aria-selected:bg-blue-500 aria-selected:text-white focus:outline focus:outline-2 focus:outline-blue-500 focus:outline-offset-2 focus:bg-white focus:text-black data-[is-extra=true]:opacity-25 formkit-disabled:opacity-50 formkit-disabled:cursor-default formkit-disabled:pointer-events-none',
    weekDays: 'flex',
    weekDay: 'flex w-[2.25em] h-[1em] m-1 items-center justify-center rounded-md font-medium lowercase',
    week: 'flex formkit-disabled:opacity-50 formkit-disabled:cursor-default formkit-disabled:pointer-events-none',
    day: ' flex items-center justify-center w-[2.25em] h-[2.25em] m-1 p-2 rounded-md bg-gray-200 aria-selected:bg-blue-500 aria-selected:text-white focus:outline focus:outline-2 focus:outline-blue-500 focus:outline-offset-2 focus:bg-white focus:text-black data-[is-extra=true]:opacity-25 formkit-disabled:opacity-50 formkit-disabled:cursor-default formkit-disabled:pointer-events-none',
    timeInput: 'w-full border-2 border-gray-300 rounded-md p-2 my-[2em] focus-visible:outline-blue-500',
    daysHeader: 'flex items-center justify-center',
    prev: 'mr-auto px-3 py-1 hover:bg-gray-100 hover:rounded-lg col-start-1 col-end-1',
    prevLabel: 'hidden',
    prevIcon: 'flex w-3 select-none [&>svg]:w-full',
    dayButton: 'appearance-none cursor-pointer px-3 py-1 border-2 rounded-lg mx-1 hover:border-blue-500',
    monthButton: 'appearance-none cursor-pointer px-3 py-1 border-2 rounded-lg mx-1 hover:border-blue-500',
    yearButton: 'appearance-none cursor-pointer px-3 py-1 border-2 rounded-lg mx-1 hover:border-blue-500',
    next: 'ml-auto px-3 py-1 hover:bg-gray-100 hover:rounded col-start-3 col-end-3',
    nextLabel: 'hidden',
    nextIcon: 'flex w-3 select-none [&>svg]:w-full',
    openButton:
      'appearance-none border-0 bg-transparent flex p-0 self-stretch cursor-pointer focus-visible:outline-none focus-visible:text-white focus-visible:bg-blue-500',
    calendarIcon:
      'flex w-8 grow-0 shrink-0 self-stretch select-none [&>svg]:w-full [&>svg]:m-auto [&>svg]:max-h-[1em] [&>svg]:max-w-[1em]',
  },
  file: {
    fileItem: 'flex items-center text-gray-800 mb-1 last:mb-0',
    fileItemIcon: 'w-4 mr-2 shrink-0',
    fileList: 'shrink grow peer p-2 formkit-multiple:data-[has-multiple="true"]:mb-6',
    fileName: 'break-all grow text-ellipsis',
    fileRemove:
      'relative ml-auto text-[0px] hover:text-red-500 pl-2 peer-data-[has-multiple=true]:text-sm peer-data-[has-multiple=true]:text-blue-500 peer-data-[has-multiple=true]:ml-3 peer-data-[has-multiple=true]:mb-1.5 formkit-multiple:bottom-[0.15em] formkit-multiple:pl-0 formkit-multiple:ml-0 formkit-multiple:left-[1em] formkit-multiple:formkit-prefix-icon:left-[3.75em]',
    fileRemoveIcon: 'block text-base w-3 relative',
    inner: 'relative flex cursor-pointer formkit-multiple:[&>button]:absolute',
    input: 'cursor-pointer text-transparent absolute top-0 right-0 left-0 bottom-0 opacity-0',
    noFiles: 'flex w-full items-center p-2 text-gray-300',
    noFilesIcon: 'w-4 mr-2',
  },
  radio: {
    decorator: 'rounded-full',
    decoratorIcon: 'w-5 p-[5px]',
  },
  range: {
    inner: '$reset flex items-center',
    input: '$reset w-full mb-1 h-2 p-0 rounded-full',
    prefixIcon:
      '$reset w-4 mr-1 flex self-stretch grow-0 shrink-0 [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
    suffixIcon:
      '$reset w-4 ml-1 flex self-stretch grow-0 shrink-0 [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
  },
  select: {
    inner:
      'flex relative items-center rounded ring-1 ring-gray-100 focus-within:ring-2 [&>span:first-child]:focus-within:text-blue-500',
    input:
      'w-full pl-3 pr-8 py-2 border-none text-base text-gray-700 placeholder-gray-300 formkit-multiple:p-0 data-[placeholder="true"]:text-gray-300 formkit-multiple:data-[placeholder="true"]:text-inherit',
    selectIcon: 'flex p-[3px] shrink-0 w-5 mr-2 -ml-[1.5em] h-full pointer-events-none',
    option: 'formkit-multiple:p-3 formkit-multiple:text-sm text-gray-700',
  },
  textarea: {
    inner: 'flex rounded ring-1 ring-gray-100 [&>label:first-child]:focus-within:text-blue-500',
    input: 'block w-full p-2 border-none text-base text-gray-700 placeholder-gray-300 focus:shadow-outline',
  },

  // PRO input styles
  autocomplete: {
    closeIcon: 'block grow-0 shrink-0 w-3 mr-1',
    inner: '[&>div>[data-value]]:absolute [&>div>[data-value]]:mb-0',
    option: 'grow text-ellipsis !py-1 px-2',
    selection: 'static flex left-0 top-0 right-0 bottom-0 mt-0 rounded bg-blue-500 text-white',
    selections: 'flex flex-wrap gap-2 mt-2',
    selectionWrapper: 'flex justify-between items-center gap-1',
  },
  mask: {
    inner:
      'flex items-center ring-1 ring-gray-100 focus-within:ring-2 [&>label:first-child]:focus-within:text-blue-500 rounded',
    input: 'w-full p-2 border-none text-base text-gray-700 placeholder-gray-300',
  },
  rating: {
    inner: 'relative flex items-center w-[8em] formkit-disabled:bg-transparent',
    itemsWrapper: 'w-full text-gray-900',
    onItems: 'text-yellow-400',
    onItemWrapper: '[&>*]:w-full [&>svg]:h-auto [&>svg]:max-w-none [&>svg]:max-h-none',
    offItems: 'text-gray-500',
    offItemWrapper: '[&>*]:w-full [&>svg]:h-auto [&>svg]:max-w-none [&>svg]:max-h-none',
  },
  repeater: {
    content: 'grow p-3 flex flex-col align-center',
    controlLabel: 'absolute opacity-0 pointer-events-none',
    controls: 'flex flex-col items-center justify-center bg-gray-100 p-3',
    downControl: 'hover:text-blue-500 disabled:hover:text-inherit disabled:opacity-25',
    fieldset: 'py-4 px-5',
    help: 'mb-1.5 mt-1.5',
    item: 'flex w-full mb-1 rounded border border-gray-200',
    moveDownIcon: 'block w-3 my-1',
    moveUpIcon: 'block w-3 my-1',
    removeControl: 'hover:text-blue-500 disabled:hover:text-inherit disabled:opacity-25',
    removeIcon: 'block w-5 my-1',
    upControl: 'hover:text-blue-500 disabled:hover:text-inherit disabled:opacity-25',
  },
  slider: {
    outer: 'max-w-md',
    help: 'mt-0 mb-1',
    sliderInner:
      'flex items-center py-1 [&>.formkit-max-value]:mb-0 [&>.formkit-max-value]:ml-8 [&>.formkit-max-value]:shrink [&>.formkit-max-value]:grow-0 [&>.formkit-icon]:bg-none [&>.formkit-icon]:border-none [&>.formkit-icon]:p-0 [&>.formkit-icon]:w-4 [&>.formkit-prefix-icon]:mr-2 [&>.formkit-suffix-icon]:ml-2 [&[data-has-mark-labels="true"]_.formkit-track]:mb-4',
    track: 'grow relative z-[3] py-1',
    trackWrapper: 'px-[2px] rounded-full bg-gray-200',
    trackInner: '!h-[6px] mx-[2px] relative',
    fill: 'h-full rounded-full absolute top-0 mx-[-4px] bg-blue-500',
    marks: 'absolute pointer-events-none left-0 right-0 top-0 bottom-0',
    mark: 'absolute top-1/2 w-[3px] h-[3px] rounded-full -translate-x-1/2 -translate-y-1/2 bg-gray-400 data-[active="true"]:bg-white',
    markLabel: 'absolute top-[calc(100%+0.5em)] left-1/2 text-gray-400 text-[0.66em] -translate-x-1/2',
    handles: 'm-0 p-0 list-none',
    handle:
      'group w-4 h-4 rounded-full bg-white absolute top-1/2 left-0 z-[2] -translate-x-1/2 -translate-y-1/2 shadow-[inset_0_0_0_1px_rgba(0,0,0,0.1),0_1px_2px_0_rgba(0,0,0,0.8)] focus-visible:outline-0 focus-visible:ring-2 ring-blue-500 data-[is-target="true"]:z-[3]',
    tooltip:
      'absolute bottom-full left-1/2 -translate-x-1/2 -translate-y-[4px] bg-blue-500 text-white py-1 px-2 text-xs leading-none whitespace-nowrap rounded-sm opacity-0 pointer-events-none transition-opacity after:content-[""] after:absolute after:top-full after:left-1/2 after:-translate-x-1/2 after:-translate-y-[1px] after:border-4 after:border-transparent after:border-t-blue-500 group-hover:opacity-100 group-focus-visible:opacity-100 group-data-[show-tooltip="true"]:opacity-100',
    linkedValues: 'flex items-start justify-between',
    minValue:
      'grow max-w-[45%] mb-0 relative [&_.formkit-inner::after]:content-[""] [&_.formkit-inner::after]:absolute [&_.formkit-inner::after]:left-[105%] [&_.formkit-inner::after]:-translate-y-1/2 [&_.formkit-inner::after]:w-[10%] [&_.formkit-inner::after]:h-[1px] [&_.formkit-inner::after]:bg-gray-500',
    maxValue: 'grow max-w-[45%] mb-0 relative',
    chart: 'relative z-[2] mb-2 flex justify-between items-center w-full aspect-[3/1]',
    chartBar: 'absolute bottom-0 h-full bg-gray-400 opacity-[.66] data-[active="false"]:opacity-[.25]',
  },
  taglist: {
    input:
      '$reset formkit-input flex rounded appearance-none bg-transparent placeholder:text-gray-300 focus:outline-none focus:ring-0 focus:shadow-none grow',
    removeSelection: 'w-2.5 mx-1 self-center text-white leading-none',
    tag: 'flex items-center pl-2 pr-1.5 py-1 bg-blue-500 text-white text-xs rounded-full',
    tagWrapper:
      'flex gap-1.5 focus:outline-none focus:text-white [&>div]:focus:bg-blue-500 [&>div>button]:focus:text-white',
    tagLabel: 'pr-1',
    tags: 'flex flex-wrap items-center gap-1 w-full py-2 px-3 text-gray-600',
  },
  toggle: {
    altLabel: 'block w-full mb-2 text-gray-900 text-s',
    inner: '$reset flex items-center h-[40px] scale-90 cursor-pointer',
    input: 'peer fixed left-[999em] opacity-0 pointer-events-none',
    label: '$reset formkit-label block text-gray-900 text-s text-left',
    innerLabel: 'text-[10px] absolute left-full top-1/2 -translate-x-full -translate-y-1/2 px-1',
    thumb: 'relative left-0 aspect-square rounded-full transition-all w-5 bg-gray-100',
    track:
      'p-0.5 min-w-[3em] relative rounded-full transition-all bg-gray-300 peer-checked:bg-blue-500 peer-checked:[&>div:last-child]:left-full peer-checked:[&>div:last-child]:-translate-x-full peer-checked:[&>div:first-child:not(:last-child)]:left-0 peer-checked:[&>div:first-child:not(:last-child)]:translate-x-0',
    valueLabel: 'text-s',
    wrapper: 'flex flex-wrap items-center',
  },
}

export const formkitThemeClassesAsArray = _.chain(formkitTheme)
  .values()
  .map(_.values)
  .flatten()
  .join(' ')
  .split(' ')
  .uniq()
  .value()
