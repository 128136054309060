<script setup lang="ts" generic="T extends Record<string, unknown>">
import { formatDistance } from 'date-fns'
import { getCurrentInstance } from 'vue'
import { getDateFnsLocaleFromBrowserLanguage } from '~/utils/formatter'

interface Column {
  key: string
  label: string
  type?: string
  align?: 'left' | 'center' | 'right'
  noWrap?: boolean
  vAlign?: 'top' | 'middle'
  maxWidth?: string
}

const emit = defineEmits<{
  rowClick: [row: T & { id: string }]
  rowSelect: [row: (T & { id: string }) | boolean]
}>()

const props = defineProps<{
  columns: Column[]
  rows: (T & { id: string })[]
  title?: string
  emptyMessage?: string
  isLoading?: boolean
  isLoadingMore?: boolean
  testId?: string
}>()

const state = reactive<{ selectedRows: string[] }>({
  selectedRows: [],
})

const currentInstance = getCurrentInstance()?.vnode
const hasRowClick = currentInstance?.props?.['onRowClick']
const hasRowSelect = currentInstance?.props?.['onRowSelect']

function onRowClick(row: T & { id: string }) {
  emit('rowClick', row)
}

function toggleRowSelect(row: T & { id: string }) {
  const index = state.selectedRows.indexOf(row.id)
  if (index === -1) {
    state.selectedRows.push(row.id)
  } else {
    state.selectedRows.splice(index, 1)
  }
  emit('rowSelect', row)
}

function toggleSelectAll() {
  if (state.selectedRows.length < props.rows.length) {
    state.selectedRows = props.rows.map(row => row.id)
    emit('rowSelect', true)
  } else {
    state.selectedRows = []
    emit('rowSelect', false)
  }
}
</script>

<template>
  <div class="flex flex-col gap-4">
    <CText v-if="props.title" size="l">
      {{ props.title }}
    </CText>

    <table class="table-border-collapse table-layout-auto w-full rounded table-border border-gray-300">
      <thead>
        <tr class="group border-b border-solid border-gray-300 border-0 text-left rounded-t">
          <th v-if="hasRowSelect" class="p-4 first:rounded-tl flex items-center justify-center">
            <button class="text-gray-500" @click.stop.prevent="toggleSelectAll">
              <div i="carbon-checkbox" v-if="state.selectedRows.length === 0" />
              <div i="carbon-checkbox-checked" v-else-if="state.selectedRows.length === props.rows.length" />
              <div i="carbon-checkbox-indeterminate" v-else />
            </button>
          </th>
          <template v-for="column in props.columns" :key="column.key">
            <th
              class="p-4 items-center justify-center first:rounded-tl last:rounded-tr"
              :class="[
                column.align === 'right' && 'text-right',
                column.align === 'center' && 'text-center',
                column.noWrap && 'whitespace-nowrap',
              ]"
              :style="{
                width: column.maxWidth,
                maxWidth: column.maxWidth,
              }"
            >
              <slot v-if="$slots[`header_${column.key}`]" :name="`header_${column.key}`" :item="column" />
              <CText size="s" muted v-else>{{ column.label }}</CText>
            </th>
          </template>
        </tr>
      </thead>
      <tbody>
        <tr v-if="props.isLoading" class="p-4 bg-white rounded-b">
          <td class="rounded-b p-8" :colspan="props.columns.length + (hasRowSelect ? 1 : 0)">
            <Loader />
          </td>
        </tr>
        <tr
          v-else-if="rows.length === 0"
          class="p-4 text-center bg-white rounded-b"
          :data-test-id="`${testId}_empty_list`"
        >
          <td class="p-4 rounded-b" :colspan="columns.length + (hasRowSelect ? 1 : 0)">
            <CText size="s" muted>{{ props.emptyMessage || $t('global.no_data') }}</CText>
          </td>
        </tr>
        <tr
          v-else
          @click="onRowClick(row)"
          v-for="(row, index) in props.rows"
          :key="row.id"
          class="group bg-white border-b last:border-0 border-gray-300 last:rounded-b hover:bg-gray-50"
          :class="{ 'cursor-pointer': hasRowClick }"
          :data-test-id="`${testId}-${index}`"
        >
          <td v-if="hasRowSelect" class="p-4 group-last:first:rounded-bl">
            <div class="flex items-center justify-center">
              <button
                class="text-gray-500"
                @click.stop.prevent="toggleRowSelect(row)"
                :data-test-id="`${testId}_select_${index}`"
              >
                <div i="carbon-checkbox-checked" v-if="state.selectedRows.includes(row.id)" />
                <div i="carbon-checkbox" v-else />
              </button>
            </div>
          </td>
          <template v-for="column in props.columns" :key="column.key">
            <td
              class="p-4 group-last:first:rounded-bl group-last:last:rounded-br"
              :class="[
                column.noWrap ? 'whitespace-nowrap' : 'break-all text-wrap whitespace-normal',
                column.vAlign === 'top' ? 'align-top' : 'align-middle',
              ]"
              :style="{
                width: column.maxWidth,
                maxWidth: column.maxWidth,
              }"
            >
              <slot v-if="$slots[column.key]" :name="column.key" :item="row" :index="index" />
              <CText size="s" v-else-if="column.type === 'date'">{{
                formatDistance(new Date(row[column.key] as string), new Date(), {
                  addSuffix: true,
                  locale: getDateFnsLocaleFromBrowserLanguage()!,
                })
              }}</CText>
              <CText size="s" v-else class="line-clamp-2">{{ row[column.key] }}</CText>
            </td>
          </template>
        </tr>
        <tr v-if="props.isLoadingMore" class="p-4 bg-white rounded-b">
          <td class="rounded-b p-4" :colspan="props.columns.length + (hasRowSelect ? 1 : 0)">
            <Loader />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
