export async function isForvia(growthBook: any) {
  return growthBook?.isOn('forvia-etl')
}

export async function getClients(growthBook: any) {
  return growthBook?.getFeatureValue('client-dropdown')
}

export async function sourcingEnabled(growthBook: any) {
  return growthBook?.isOn('sourcing')
}
