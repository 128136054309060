import tippy, { Instance } from 'tippy.js'

export function useTooltip({ selector, content }: { selector: string; content?: string }) {
  const instances = ref<Instance[] | null>(null)

  function setUp() {
    const options: Parameters<typeof tippy>[1] = {}

    if (content) {
      options.content = content
    }

    instances.value = tippy(selector, options)
  }

  function destroy() {
    if (instances.value) {
      instances.value.forEach(instance => instance.destroy())
    }

    instances.value = null
  }

  return {
    setUp,
    destroy,
  }
}
